
import Vue, { PropOptions } from 'vue'
import { mdiCheckBold } from '@mdi/js'
export default Vue.extend({
  props: {
    customItems: {
      type: Array,
      default: () => [],
      required: false,
    } as PropOptions<
      {
        title: string
        link: string
        icon: string
        chipItems: {
          text: string
        }[]
      }[]
    >,
    title: {
      type: String,
      default: 'スリーズプロの機能一覧',
      required: false,
    },
    cols: {
      type: String,
      default: '2',
      required: false,
    },
    childCols: {
      type: String,
      default: '4',
      required: false,
    },
  },

  data() {
    return {
      mdiCheckBold,
      items: [
        {
          title: 'CRM',
          link: '/functions/crm',
          icon: 'pie-chart',
          chipItems: [
            { text: 'オペレーション全自動集計' },
            { text: '保険/自費/物販、保険請求の売上集計' },
            { text: '分析期間のカスタム設定' },
            { text: '異なる期間との比較' },
            { text: '推移分析' },
            { text: 'エリア分析' },
            { text: 'グループ店舗分析' },
            { text: '来院分析' },
            { text: '来院平均分析' },
            { text: 'LTV分析（顧客生涯価値）' },
            { text: '顧客タイプ分析' },
            { text: 'セグメント分析' },
            { text: 'リピート・離脱分析' },
            { text: 'タグ分析' },
            { text: '売上構成平均分析' },
            { text: '人時生産性分析' },
            { text: '院内稼働率分析' },
            { text: '支払い方法内訳分析' },
            { text: '商品分析' },
            { text: '売れ筋商品分析' },
            { text: '購入履歴分析' },
            { text: '個人スタッフランキング' },
            { text: '支店ランキング' },
            { text: '商品ランキング' },
            { text: 'スタッフCRM分析' },
            { text: '店舗別スタッフ分析' },
            { text: 'ヘルプスタッフ分析' },
            { text: 'スタッフ別患者離脱分析/抽出' },
          ],
        },
        {
          title: 'POS',
          link: '/functions/pos',
          icon: 'pos',
          chipItems: [
            { text: '支払い方法' },
            { text: '未収金表示' },
            { text: '過不足金' },
            { text: 'レジ金の自動集計' },
            { text: '締め作業機能' },
            { text: 'キャッシュレジスター連動' },
            { text: 'レシート機能' },
            { text: '「保険分合計」印字対応' },
            { text: '午前/午後の売上集計' },
            { text: '「保険・自費・物販」を個別管理' },
            { text: 'カテゴリーの設定' },
            { text: '残り枚数の管理' },
            { text: 'ギフト券・ポイントに対応' },
            { text: 'クレジット決済対応' },
          ],
        },
        {
          title: '予約',
          link: '/functions/reservations',
          icon: 'solid-calendar',
          chipItems: [
            { text: '遠隔管理' },
            { text: 'スタッフ別・ベット別表示の切り替え' },
            { text: '視覚的に分かり易い時間表示' },
            { text: 'ステイタス管理' },
            { text: '個人別予約数の管理・分析' },
            { text: 'ピークタイム分析' },
            { text: '任意のベット名管理' },
            { text: '複数ベット管理' },
            { text: '複数施術者管理' },
            { text: '複数店舗に所属している施術者管理' },
            { text: '次回予約の設定' },
            { text: 'トータル来院回数の表示' },
            { text: 'ラベリング機能' },
            { text: 'アラート機能' },
            { text: 'コメント機能' },
            { text: '複数ルートからの新患獲得' },
            { text: '流入経路' },
            { text: 'ダブルブッキング防止' },
            { text: 'Web予約メニューの設定' },
            { text: '休憩時間' },
            { text: '固定シフト・変動シフト対応' },
            { text: 'シフトコピー機能(週単位)' },
            { text: '労働時間の管理' },
            { text: '予約リマインダー・サンクスメール設定' },
            { text: 'ステップメール' },
            { text: '一斉メール配信' },
            { text: 'ビラ配布管理' },
          ],
        },
        {
          title: '電子カルテ',
          link: '/functions/karte',
          icon: 'person-comment',
          chipItems: [
            { text: 'Web予約からの自動データ取得' },
            { text: '保険情報' },
            { text: '購入履歴' },
            { text: 'チケット情報' },
            { text: '利用可能ポイント' },
            { text: '複数パターン作成（柔整/鍼灸/自費など）' },
            { text: '写真保存機能' },
            { text: '画像カスタマイズ' },
            { text: 'カスタム情報管理' },
            { text: '経過記録' },
            { text: '文字選択' },
            { text: '項目カスタマイズ' },
            { text: 'QRコード' },
            { text: '来院前問診' },
            { text: 'カルテへの反映' },
            { text: '問診情報の活用' },
          ],
        },
      ] as {
        title: string
        link: string
        icon: string
        chipItems: {
          text: string
        }[]
      }[],
    }
  },
  computed: {
    viewItems(): {
      title: string
      link: string
      icon: string
      chipItems: {
        text: string
      }[]
    }[] {
      return this.customItems.length === 0 ? this.items : this.customItems
    },
  },
})
