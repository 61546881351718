
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      contactInfo: [
        {
          icon: 'mail',
          title: 'お問い合わせ',
          desc: '機能詳細や導入に関してなど、あなたのお悩みや不明点にお答えします。',
          button: '相談する',
        },
        {
          icon: 'yens',
          title: 'お見積り',
          desc: 'お客さまの状況に合わせ、最適なプランをご案内します。',
          button: 'プランを見る',
        },
        {
          icon: 'phone',
          title: '無料トライアル',
          desc: '最大90日間の無料トライアルで実際の機能をお試しいただけます。',
          button: '試してみる',
        },
        {
          icon: 'health-clinic',
          title: '導入店舗見学',
          desc: '導入している実際の店舗でオペレーションの様子など見学できます。',
          button: '見学する',
        },
      ],
    }
  },
  methods: {
    setFormType(value: string) {
      this.$store.commit('setFormType', value)
    },
  },
})
