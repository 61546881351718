
import Vue from 'vue'
import { mdiCheckCircle } from '@mdi/js'

export default Vue.extend({
  props: {
    iconColor: {
      type: String,
      required: false,
      default: '#fff',
    },
    textColor: {
      type: String,
      required: false,
      default: '#fff',
    },
  },
  data() {
    return {
      mdiCheckCircle,
    }
  },
})
