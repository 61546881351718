
import Vue from 'vue'
import { gql } from 'graphql-request'
import query from '~/apollo/queries/news/news.gql'
import { NewsCard } from '~/types'

export default Vue.extend({
  async fetch() {
    try {
      const newsQuery = gql`
      query News {
        news (sort:  ${JSON.stringify(
          'publishedOn' + ':desc'
        )}, pagination: { pageSize: ${this.pageNum}}) ${query.loc?.source.body}
      }
    `
      const {
        news: { data },
      } = await this.$graphql.default.request(newsQuery)
      this.posts = data
    } catch (e) {}
  },
  data() {
    return {
      posts: [] as {
        attributes: {
          slug: string
          title: string
          description: string
          publishedOn: string
        }
      }[],
      page: 1,
      pageCount: 1,
      blogCategory: 'news',
      pageNum: 5,
    }
  },
  computed: {
    formatPosts(): NewsCard[] {
      return this.posts.map((item) => {
        return {
          title: item.attributes.title,
          createdAt: item.attributes.publishedOn,
          path: item.attributes.slug,
        }
      })
    },
  },
  // methods: {
  //   async getPostsOfPage($content: any, page: number, blogCategory: string) {
  //     this.posts = await $content(blogCategory)
  //       .only(['path', 'title', 'createdAt'])
  //       .sortBy('createdAt', 'desc')
  //       .skip(this.pageNum * (page - 1))
  //       .limit(this.pageNum)
  //       .fetch()
  //   },
  //   async getNumberOfPages($content: any, blogCategory: string) {
  //     this.pageCount = Math.ceil(
  //       (await $content(blogCategory).only([]).fetch()).length / this.pageNum
  //     )
  //   },
  //   async onPageChange(page: number) {
  //     this.page = page
  //     await this.getPostsOfPage(this.$content, this.page, this.blogCategory)
  //   },
  // },
})
