
import Vue, { PropOptions } from 'vue'
import { mdiStar } from '@mdi/js'
import { StoryCard } from '~/types/index'
export default Vue.extend({
  props: {
    item: {
      type: Object,
      required: true,
    } as PropOptions<StoryCard>,
  },
  data() {
    return { mdiStar }
  },
})
