
import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    featureList: {
      type: Array,
      required: true,
    },
  },
})
