
import Vue from 'vue'
import { reservationManagementItems } from '~/types'

export default Vue.extend({
  data() {
    return {
      img1: require('~/assets/images/homeCalandar.svg'),
      img2: require('~/assets/images/homeImage3.svg'),
      items: [
        {
          id: 'reservation_management',
          title: 'CRM',
          description:
            'Everything starts with a visual board — the core of monday.com Work OS. Tailor it your way and manage anything from projects to departments',
          imgSrc: '/crm/function/crmFunction1.png',
          icon: 'calender',
          iconText: 'Reservation Management',
          link: '',
          linkText: 'Know More',
        },
        {
          id: 'staff_management',
          title: 'POS',
          description:
            'Everything starts with a visual board — the core of monday.com Work OS. Tailor it your way and manage anything from projects to departments',
          imgSrc: '/crm/function/crmFunction1.png',
          icon: 'people-time',
          iconText: 'Staff Management',
          link: '',
          linkText: 'Know More',
        },
        {
          id: 'products_management',
          title: '予約',
          description:
            'Everything starts with a visual board — the core of monday.com Work OS. Tailor it your way and manage anything from projects to departments',
          imgSrc: '/crm/function/crmFunction1.png',
          icon: 'database',
          iconText: 'Products Management',
          link: '',
          linkText: 'Know More',
        },
        {
          id: 'branch_management',
          title: '電子カルテ',
          description:
            'Everything starts with a visual board — the core of monday.com Work OS. Tailor it your way and manage anything from projects to departments',
          imgSrc: '/crm/function/crmFunction1.png',
          icon: 'building',
          iconText: 'Branch Management',
          link: '',
          linkText: 'Know More',
        },
      ],
      reservationManagement: {
        img: '/top/reservationPhoneJap.png',
        sectionNumber: '01',
        desc: '予約管理や問診票の活用・カルテの記入・会計作業・レジ締め業務からスタッフ評価まで、不要な集計や管理作業がなくなり、効率的な院内業務が可能になり、多くの時間が生まれ、店舗運営に必要な業務に集中できます。',
      } as reservationManagementItems,
      patinetDataManagement: {
        img: '/top/consolidate.png',
        sectionNumber: '02',
        title:
          '最新の患者・スタッフ・店舗データが自然に集まり、一元管理できる。',
        desc: '来院患者のカルテ探しから、回数券の管理、数週間後の予約確認など、あらゆるシーンで必要な院内業務がスマート治療院で完結。自動でデータベースが更新されます。患者・スタッフ情報が自然に集約されるので、常に最新で間違いのない店舗データをカンタンに一元管理できます。',
      },
      storeManagement: {
        img: require('~/assets/featureCRM.png'),
        sectionNumber: '03',
        desc: 'データ分析の専門知識がない担当者でもわかりやすい視認性と操作性で、蓄まったデータの可視化・分析がカンタンに。患者分析・スタッフ評価・店舗運営の課題の発見など、効率的で納得感のある店舗マネージメントを支援します。',
      },
    }
  },
})
