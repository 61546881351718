export function formatDate(date: string | number | Date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()
  const hours = ('0' + d.getHours()).slice(-2)
  const minutes = ('0' + d.getMinutes()).slice(-2)

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return `${[year, month, day].join('-')} ${[hours, minutes].join(':')}`
}
