
import Vue, { PropOptions } from 'vue'
import { formatDate } from '~/helpers/formatUtils'
export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: true,
    } as PropOptions<
      {
        path: string
        title: string
        createdAt: string
      }[]
    >,
  },
  methods: {
    formatDate,
  },
})
