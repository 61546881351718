
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      img: '~/assets/images/homeCalandar.svg',
    }
  },
})
