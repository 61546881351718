
import Vue from 'vue'
import { gql } from 'graphql-request'
import interview from '~/apollo/queries/interview/interview.gql'
import { StoryCard } from '~/types/index'
export default Vue.extend({
  props: {
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    background: {
      type: String,
      required: false,
      default: 'rgba(2, 195, 204, 0.1)',
    },
  },
  async fetch() {
    try {
      const interviewQuery = gql`
        query Interview {
          interviews (sort: "order", pagination: { page: ${this.page}, pageSize: ${this.pageNum}}) ${interview.loc?.source.body}
        }
      `
      const {
        interviews: { data },
      } = await this.$graphql.default.request(interviewQuery)
      this.posts = data
    } catch (e) {}
  },
  data() {
    return {
      posts: [] as {
        attributes: {
          slug: string
          title: string
          description: string
          publishedOn: string
          clinicName: string
          customerName: string
          topImage: {
            data: {
              attributes: { url: string; alternativeText: string; name: string }
            }
          }
          customerImageAlt: string
          star: number
        }
      }[],
      page: 1,
      pageCount: 1,
      pageNum: 15,
      items: [
        {
          name: '渡辺淳一 様',
          clinicName: '堺整骨院',
          star: 5,
          textHeading:
            '「創業34年」堺整骨院グループ・代表取締役社長 渡辺淳一様インタビュー 「スリーズプロの導入で、無理・ムラ・無駄をなくして業務改善」',
          text: '1989年の創業以来、西日本・九州中心に整骨院を24院展開し、整骨院業界の最前線にいる「株式会社堺整骨院西」。施術家で代表取締役社長でもある渡辺淳一氏にスリーズプロについて話をお伺いしました。',
          img: 'images/interviews/sakai/top-image.png',
          alt: 'userImg01',
          path: '/interviews/sakai-junichi-watanabe',
          link: '/interviews/sakai-junichi-watanabe',
        },
        {
          name: '入舟勇志 様',
          clinicName: '株式会社メディカルアース',
          star: 5,
          textHeading:
            ' 中国・四国地方でトップクラスの店舗展開、株式会社メディカルアース統括マネージャー 入舟勇志様インタビュー「導入する勇気と決断、デジタルに不慣れなスタッフでも直感的に操作でき、すぐ慣れました」',
          text: '株式会社メディカルアース様は、中国・四国地方で10店舗以上を展開している鍼灸整骨院院グループです。20代で統括マネージャーになられた入舟勇志様にスリーズプロについて話をお伺いしました。',
          img: 'images/interviews/medical-earth/top-image.png',
          alt: 'userImg03',
          path: '/interviews/medical-earth-isamushi-irisu',
          link: '/interviews/medical-earth-isamushi-irisu',
        },
      ],
    }
  },
  computed: {
    formatPosts(): StoryCard[] {
      return this.posts.map((item) => {
        return {
          img: item.attributes.topImage.data?.attributes.url,
          alt: item.attributes.topImage.data?.attributes.alternativeText,
          name: item.attributes.customerName,
          clinicName: item.attributes.clinicName,
          title: item.attributes.title,
          description: item.attributes.description,
          articleLink: item.attributes.slug,
          star: item.attributes.star,
        }
      })
    },
  },
  methods: {
    goToOtherPage(link: string) {
      this.$router.push(`/interviews/${link}`)
    },
  },
})
