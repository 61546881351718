
import Vue, { PropOptions } from 'vue'
import { mdiCheckCircleOutline } from '@mdi/js'
import { reservationManagementItems } from '~/types'
export default Vue.extend({
  props: {
    data: {
      type: Object,
      required: true,
    } as PropOptions<reservationManagementItems>,
    flexReverse: {
      type: String,
      required: false,
      default: 'flex-row',
    },
    cardBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiCheckCircleOutline,
    }
  },
})
