
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      img1: require('~/assets/images/homeCalandar.svg'),
      img2: require('~/assets/images/homeImage3.svg'),
    }
  },
})
