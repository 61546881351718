
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      chipItems: [
        {
          text: '接骨院・整骨院',
        },
        {
          text: '鍼灸院',
        },
        {
          text: '整体院',
        },
        {
          text: '鍼灸整骨院',
        },
        {
          text: '有料老人ホーム',
        },
        {
          text: 'リラクゼーションサロン',
        },
        {
          text: 'マッサージ',
        },
        {
          text: '訪問マッサージ',
        },
        {
          text: '訪問鍼灸',
        },
        {
          text: '訪問美容',
        },
        {
          text: '美容鍼灸サロン',
        },
        {
          text: 'スポーツジム・フィットネスクラブ',
        },
        {
          text: 'ストレッチ専門店',
        },
        {
          text: 'エステサロン',
        },
        {
          text: 'アイラッシュサロン',
        },
        {
          text: 'ヨガスタジオ',
        },
        {
          text: 'ピラティス',
        },
        {
          text: 'その他',
        },
      ],
    }
  },
})
