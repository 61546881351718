
import Vue from 'vue'
import { Items } from '~/types'

export default Vue.extend({
  data() {
    return {
      title: 'カンタンにはじめられる、Smart治療院',
      featureList: [
        {
          img: require('~/assets/topFeature1.png'),
          title: 'スタッフ分析',
          desc: 'スタッフひとりひとりの正確な数字から適正な人事評価・離職防止につながり、人時生産性から時給や給与などの基準も明確になります',
        },
        {
          img: require('~/assets/topFeature2.png'),
          title: '顧客分析 ',
          desc: '売上高・窓口単価・顧客あたりの単価などはもちろん、新患・再来院・継続・離脱・セルフケアなど顧客タイプ別の分析も可能です',
        },
        {
          img: require('~/assets/topFeature3.png'),
          title: 'カスタム電子カルテ',
          desc: '施術業界に特化した店舗毎にカスタムできる電子カルテで、各店舗の特徴を残しつつ、スマート治療院への移行が可能でです。',
        },
      ] as Items[],
    }
  },
})
