
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      list: [
        {
          img: require('~/assets/visualLogo1.svg'),
          title: '業務効率化',
          desc: '全ての業務をデジタル化、Web予約・電子カルテで工数を大幅削減',
        },
        {
          img: require('~/assets/dataManagementLogo.svg'),
          title: 'データを一元管理',
          desc: '必要な院内業務がタブレット１つで完結。院運営の中で、最新データが自然に集約',
        },
        {
          img: require('~/assets/projectManagementLogo.svg'),
          title: '売上向上に繋がる経営',
          desc: 'デジタルデータをもとに、スタッフ評価や顧客の離脱防止、単価アップなどのマネジメントに活用',
        },
      ],
    }
  },
})
