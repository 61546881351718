
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      patinetDataManagement: {
        img: require('~/assets/homeFeature1.svg'),
        sectionNumber: '02',
        title:
          '最新の患者・スタッフ・店舗データが自然に集まり、一元管理できる。',
        desc: '来院患者のカルテ探しから、回数券の管理、数週間後の予約確認など、あらゆるシーンで必要な院内業務がスマート治療院で完結。自動でデータベースが更新されます。患者・スタッフ情報が自然に集約されるので、常に最新で間違いのない店舗データをカンタンに一元管理できます。',
      },
    }
  },
})
