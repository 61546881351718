
import Vue from 'vue'
export default Vue.extend({
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    quality: {
      type: String,
      required: false,
      default: '100',
    },
    fit: {
      type: String,
      required: false,
      default: 'cover',
    },
  },
})
