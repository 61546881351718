
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#23221f',
      required: false,
    },
    weight: {
      type: String,
      default: '800',
      required: false,
    },
  },
})
